// export const APP_URL = `http://localhost:3001/api/v1`;
export const APP_URL = `https://backend.production.cjh.agifly.cloud/api/v1`;
export const API_URL = `${APP_URL}`;
export const FILE_BASE_URL = 'https://backend.production.cjh.agifly.cloud';
//export const FILE_BASE_URL = 'http://localhost:3000';
// export const FILE_BASE_URL = 'https://backend.develop.cjh.agifly.cloud';
// export const FILE_BASE_URL = 'http://localhost:3000';

export const NODE_ENV = "developemnt"
// export const NODE_ENV = "production"
